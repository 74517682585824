import React, { useState, useEffect } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { loadData, submitUserInfo } from './LandingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import {
  Page,
  SectionHero,
  SectionVideo,
  SectionTechnology,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionWhyUs,
  SectionPopularTutors,
  Modal,
  Form,
  FieldTextInput,
  FieldCheckbox,
  Button,
  FieldRadioButton,
  SectionOnlineLessons,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/facebook-1200x630.jpg';
import twitterImage from '../../assets/twitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    popularTutors,
    onManageDisableScrolling,
    onSubmit,
    submitUserInfoSuccess,
    submitUserInfoError,
    submitUserInfoInProgress,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const [isRSVPModalOpen, setIsRSVPModalOpen] = useState(false);


  const nameMessage = intl.formatMessage({
    id: 'LandingPage.name',
  });
  const namePlaceholderMessage = intl.formatMessage({
    id: 'LandingPage.namePlaceholder',
  });
  const nameRequiredMessage = intl.formatMessage({
    id: 'LandingPage.nameRequired',
  });

  const emailMessage = intl.formatMessage({
    id: 'LandingPage.email',
  });
  const emailPlaceholderMessage = intl.formatMessage({
    id: 'LandingPage.emailPlaceholder',
  });
  const emailRequiredMessage = intl.formatMessage({
    id: 'LandingPage.emailRequired',
  });

  const emailInvalidMessage = intl.formatMessage({
    id: 'ConfirmSignupForm.emailInvalid',
  });

  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  const requiredMessage = intl.formatMessage({
    id: 'LandingPage.requiredMessage',
  });

  const rsvpFormProps = {
    initialValues:{}
  }

  const cookies = typeof window !== 'undefined' ? document.cookie.split('; ').reduce((acc, c) => {
    const [name, value] = c.split('=');
    return { ...acc, [name]: decodeURIComponent(value) };
  }, {}) : null;

  if(typeof window !== 'undefined' && submitUserInfoSuccess){
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = 'rsvp=1; path=/; expires=' + expirationDate.toGMTString();
  }
  

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} setIsRSVPModalOpen={setIsRSVPModalOpen} />
          </div>
          {/* <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                {
                  !config.custom.preLaunch ? 
                  <SectionLocations /> : null
                }
              </div>
            </li>
          </ul> */}
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionWhyUs}>
                <SectionWhyUs />
              </div>
            </li>
          </ul>
          <SectionVideo />
          {/* <ul className={css.sections}>
            <li className={css.section}>
              {
                !config.custom.preLaunch && popularTutors && popularTutors.length > 0 ? (
                  <SectionPopularTutors 
                    rootClassName={css.popularTutors}
                    intl={intl}
                    popularTutors={popularTutors}
                  />
                ) : 

                <Modal
                  id="RSVP"
                  isOpen={isRSVPModalOpen}
                  onClose={() => setIsRSVPModalOpen(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  containerClassName={css.modalContainer}
                >
                <FinalForm
                  {...rsvpFormProps}
                  formId='rsvpForm'
                  onSubmit={(e) => {
                    if (cookies && cookies.rsvp !== '1') {
                      onSubmit(e);
                    }
                  }}
                  render={fieldRenderProps => {
                    const { form, invalid, handleSubmit, pristine, submitting } = fieldRenderProps;
                    const submitDisabled = invalid || pristine || submitting || submitUserInfoInProgress || submitUserInfoSuccess;
                    return (
                      <form
                        onSubmit={handleSubmit}
                      >
                        <FieldTextInput
                          id="name"
                          name="name"
                          className={css.name}
                          type="text"
                          label={nameMessage}
                          placeholder={namePlaceholderMessage}
                          validate={validators.required(nameRequiredMessage)}
                          autoFocus
                        />

                        <FieldTextInput
                          id="email"
                          name="email"
                          className={css.email}
                          type="email"
                          label={emailMessage}
                          placeholder={emailPlaceholderMessage}
                          validate={validators.composeValidators(validators.required(emailRequiredMessage), emailValid) }
                        />
                        <label>How do you want to signup?</label>
                        <FieldRadioButton id="student" className={css.userType} name="userType" label="As a Student" value="Student" validate={validators.required(requiredMessage)} />
                        <FieldRadioButton id="teacher" className={css.userType} name="userType" label="As a Teacher" value="Teacher" validate={validators.required(requiredMessage)} />

                        <Button style={{ marginTop: 24 }} type="submit" disabled={submitDisabled} inProgress={submitUserInfoInProgress} ready={submitUserInfoSuccess}>
                          Submit
                        </Button>
                      </form>
                    );
                  }}
                />
                  
                </Modal>
              }
            </li>
          </ul> */}
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionFilteredSearches />
              </div>
            </li>
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionOnlineLessons />
              </div>
            </li> */}
          </ul>
          <SectionTechnology className={`${css.hero} ${config.custom.preLaunch ? css.sectionTechnology : ''}`} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    promotedListingRefs,
    submitUserInfoSuccess,
    submitUserInfoError,
    submitUserInfoInProgress,
  } = state.LandingPage;
  const popularTutors = getMarketplaceEntities(state, promotedListingRefs);
  const { currentUserListing, currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    popularTutors: popularTutors.slice(0,5),
    submitUserInfoSuccess,
    submitUserInfoError,
    submitUserInfoInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSubmit: (values) =>
  dispatch(submitUserInfo(values)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;
